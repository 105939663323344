import { Controller } from "stimulus"
import { csrfToken } from '@/lib/CSRFTokens'

export default class extends Controller {
  static targets = [
    'activeToggleWrapper',
    'data',
    'defaultFilter',
    'filter',
    'markAllButton',
    'row',
    'search',
    'selectAll',
    'rowCheckbox',
    'table',
  ]

  get shouldMarkAll() {
    return this.selectAllTarget.checked ||
      !this.rowCheckboxTargets.find(x => x.checked)
  }

  get dataUrl() {
    const url = new URL(this.data.get('url'), window.location)
    new URL(window.location).searchParams.forEach((value, key) => {
      url.searchParams.set(key, value)
    })

    if (!url.searchParams.get('date')) {
      url.searchParams.set('date', this.dateParam)
    }

    return url
  }

  get dateParam () {
    const rawDate = new URLSearchParams(window.location.search).get('date')

    let date
    try {
      date = new Date(rawDate || new Date())
    } catch (e) {
      logger.error('Error parsing date', e)
      date = new Date()
    }

    return date || moment(date).format('YYYY-MM-DD')
  }

  initialize() {
    $.fn.dataTable.moment( 'MM/DD/YYYY' );
    $.fn.dataTable.moment( 'MM/DD/YYYY h:mm a' );
    $.fn.dataTable.moment = function ( format, locale ) {
      const types = $.fn.dataTable.ext.type;

      // Add type detection
      types.detect.unshift(function ( d ) {
        return moment(d, format, locale, true).isValid()
          ? 'moment-'+format
          : null
      })

      // Add sorting method - use an integer for the sorting
      types.order[ 'moment-'+format+'-pre' ] = function (d) {
        return moment(d, format, locale, true).unix();
      }
    }
  }

  connect() {
    this.dataTarget.addEventListener('click', this.handleNavigation.bind(this))
    this.loading = 0
    this.loadingUrl = null

    if (String(this.data.get('preloaded')) === 'true') {
      this.loadingUrl = this.dataUrl.toString()
      this.dispatchUpdate()
    } else {
      this.load()
    }
  }

  tableTargetConnected() {
    const columnCount = this.tableTarget.rows[0].cells.length - 1
    const hiddenPresence = columnCount - 4
    const hiddenAbsenceReason = columnCount - 3
    const hiddenUpdatedBy = columnCount - 2
    const hiddenUpdatedAt = columnCount - 1

    //initialize the datatable in the dom
    $(this.element).DataTable({
      bAutoWidth: false,
      retrieve: true,
      paging: false,
      searching: false,
      ordering: false,
      columnDefs: [
        {
          searchable: true,
          orderable: false,
          targets: [0, -1]
        },
        {
          targets: [hiddenPresence, hiddenAbsenceReason, hiddenUpdatedBy, hiddenUpdatedAt],
          visible: false
        }
      ],
      language: {
        info: "Showing _END_ entries",
      }
    });

    this.rowCheckboxTargets.map(x => x.checked = false)
    this.selectAllTarget.checked = false
    this.setSelectionText()
  }

  afterFetching() {
    this.loading--
    if (this.loading < 1) {
      document.getElementById('ajax_working').style.display = 'none'
    }
  }

  clearFilters(ev) {
    ev.preventDefault()

    const url = this.dataUrl

    url.search = ''
    url.searchParams.set('date', this.dateParam)

    this.filterTargets.forEach((el) => {
      el.classList.remove('active')
      logger.debug('Clearing', el.tagName, el)
      if (el.tagName === 'INPUT') {
        el.value = ''
      }
    })

    this.defaultFilterTargets.forEach((el) => {
      el.classList.add('active')
    })

    this.debouncedLoad(url)
  }

  clearRoster(){
    if (window.confirm("Are you sure you'd like to clear the roster? This will delete all the attendance records for this date.")) {
      const url = new URL(this.data.get('clearRosterUrl') || '/attendances/clear_roster', window.location)
      url.searchParams.set('date', this.dateParam)

      const options = {
        method: 'delete',
        credentials: 'include',
        mode: 'same-origin',
        headers: {
          'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content,
          'Content-Type': 'application/json',
        }
      }

      document.getElementById("ajax_working").style.display = "block"
      this.loading++

      fetch(url, options)
        .then((response) => {
          if (!response.ok) {
            throw new Error(response.statusText)
          } else {
            this.triggerReload()
          }
        })
        .catch((error) => {
          document.getElementById("flash-message").innerHTML = error;
        })
        .finally(() => this.afterFetching())
    }
  }

  createDailyRoster() {
    const url = new URL(this.data.get('createRosterUrl') || '/attendances/create_roster', window.location)
    url.searchParams.set('date', this.dateParam)

    const options = {
      method: 'post',
      credentials: 'include',
      mode: 'same-origin',
      headers: {
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content,
        'Content-Type': 'application/json',
      }
    }

    document.getElementById("ajax_working").style.display = "block"
    this.loading++
    const flash = document.getElementById('flash-message')

    fetch(url, options)
      .then(function(response){
        if (!response.ok) {
          throw new Error(response.statusText)
        } else {
          this.loading++

          flash.innerHTML = `
            <p class="tw-bg-k-green-300 tw-rounded-sm tw-border tw-text-black">
              Roster is being created in the background and will be completed shortly
            </p>
          `

          setTimeout(() => {
            if (flash.innerText.includes('Roster')) {
              flash.innerHTML = ''
            }
            this.debouncedLoad()
            this.afterFetching()
          }, 30000)
        }
      })
      .catch(function(error) {
        document.getElementById("flash-message").innerHTML = error;
      })
      .finally(() => this.afterFetching())
  }

  debouncedLoad(url, force) {
    const oldLoader = this.loader?.abort?.() || Promise.resolve()

    let resolve

    const promise = new Promise((_resolve) => {
      resolve = _resolve
    })

    promise.abort = () => {
      resolve()
      return promise
    }

    if (!url) {
      url = this.dataUrl
    }

    if (!force && this.loadingUrl === url.toString()) {
      resolve()
      return promise
    }

    try {
      this.loading++
      document.getElementById('ajax_working').style.display = 'block'

      const pageUrl = new URL(window.location)
      if (pageUrl.search !== url.search) {
        pageUrl.search = url.search
        window.history.pushState({}, '', pageUrl)
      }

      promise.then(() => this.afterFetching())

      const execute = () => {
        promise.abort = () => promise
        Promise.resolve()
          .then(() => oldLoader)
          .then(() => {
            if (force) this.loadingUrl = ''
            this.load()
          })
          .finally(resolve)
      }

      if (force) {
        execute()
      } else {
        const timeout = setTimeout(execute, 3000)
        promise.abort = () => {
          clearTimeout(timeout)
          setImmediate(resolve)
          promise.abort = () => promise
          return promise
        }
      }

      this.loader = promise
    } catch (e) {
      promise.abort()

      logger.error(e)

      this.afterFetching()
    }
    return promise
  }

  dispatchUpdate() {
    Array.from(document.getElementsByClassName('watch-attendance-table')).forEach((element) => {
      element.dispatchEvent(new Event('attendanceTableUpdated'))
    })
  }

  exportCSV(ev) {
    ev?.preventDefault?.()
    const url = this.dataUrl
    const date = this.dateParam

    if (ev?.currentTarget?.dataset?.fullCsv) {
      url.search = ''
    }

    url.searchParams.set('date', date)
    url.searchParams.set('format', 'csv')
    url.searchParams.delete('page')
    url.pathname = url.pathname.replace(/\.[^.]+$/, '.csv')

    const a = document.createElement('a')
    a.href = url
    a.download = `attendance-report-${date}.csv`
    a.click()
    a.remove()
  }

  filterActives(ev) {
    ev.preventDefault()
    this.setActive(ev)

    const url = this.dataUrl

    if (!url.searchParams.has('show_deleted')) return

    url.searchParams.delete('show_deleted')
    url.searchParams.delete('page')

    this.debouncedLoad(url)
  }

  filterAllRecords(ev) {
    ev.preventDefault()
    this.setActive(ev)

    const url = this.dataUrl

    if (url.searchParams.has('show_deleted')) return

    url.searchParams.set('show_deleted', true)
    url.searchParams.delete('page')

    this.debouncedLoad(url)
  }

  filterCareLevels(ev) {
    ev.preventDefault()
    this.setActive(ev)

    const url = this.dataUrl
    const value = ev.currentTarget.dataset.locName

    if (value === url.searchParams.get('loc_name')) return

    if (!value || value === 'all') {
      if (!url.searchParams.has('loc_name')) return
      url.searchParams.delete('loc_name')
    } else {
      if (value === url.searchParams.get('loc_name')) return
      url.searchParams.set('loc_name', value)
    }

    url.searchParams.delete('page')

    this.debouncedLoad(url)
  }

  filterPatients(ev) {
    ev.preventDefault()

    const url = this.dataUrl
    const value = ev.currentTarget.value

    if (value) {
      if (value === url.searchParams.get('search')) return
      url.searchParams.set('search', value)
    } else {
      if (!url.searchParams.has('search')) return
      url.searchParams.delete('search')
    }

    url.searchParams.delete('page')

    this.debouncedLoad(url)
  }

  filterPrograms(ev) {
    ev.preventDefault()
    this.setActive(ev)

    const url = this.dataUrl
    const value = ev.currentTarget.dataset.programId

    if (!value || value === '0') {
      if (!url.searchParams.has('program_id')) return
      url.searchParams.delete('program_id')
    } else {
      if (value === url.searchParams.get('program_id')) return
      url.searchParams.set('program_id', value)
    }

    url.searchParams.delete('page')

    this.debouncedLoad(url)
  }

  handleNavigation(event) {
    const link = event.target.closest('a.attendance-pagination')
    if (!link?.href) return;

    const url = new URL(link.href, window.location)
    event.preventDefault()
    const newLocation = new URL(window.location)
    const page = Math.max(Number(url.searchParams.get('page')) || 1, 1)

    if (page < 2) newLocation.searchParams.delete('page')
    else newLocation.searchParams.set('page', page)

    window.scrollTo(0, 0)
    this.debouncedLoad(newLocation)
  }

  load() {
    const url = this.dataUrl

    if (this.loadingUrl === url.toString()) return

    this.loadingUrl = url.toString()
    this.loading++

    try {
      document.getElementById('ajax_working').style.display = 'block'

      const options = {
        credentials: 'include',
        mode: 'same-origin',
        headers: {
          'X-CSRF-Token': csrfToken(),
        }
      }

      return fetch(url, options)
        .then(response => response.text())
        .then(html => {
          if (this.loadingUrl !== url.toString()) return;

          this.dataTarget.innerHTML = html;
          this.dispatchUpdate()
        })
        .catch(err => {
          logger.error(err)
        })
        .finally(() => this.afterFetching())
    } catch (e) {
      logger.error(e)
      this.afterFetching()

      return Promise.reject(e)
    }
  }

  onCheckboxUpdated(ev) {
    if (this.selectAllTarget.checked && ev.target.checked === false) {
      this.selectAllTarget.checked = false
    }

    this.setMarkAllText()
  }

  onSelectAllChange() {
    const checked = this.selectAllTarget.checked

    if (checked) {
      this.rowCheckboxTargets.map(x => x.checked = true)
    } else if (!this.rowCheckboxTargets.find(x => !x.checked)) {
      this.rowCheckboxTargets.map(x => x.checked = false)
    }

    this.setMarkAllText()
  }

  openBulkUpdateModal() {
    const options = {
      credentials: 'include',
      mode: 'same-origin',
      headers: {
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content,
      }
    }

    if (!this.shouldMarkAll) {
      const selected = this.rowCheckboxTargets.filter(x => x.checked)

      if (selected.length < 1) {
        alert('Please select at least one record')
        return
      }
    }

    document.getElementById("ajax_working").style.display = "block";

    fetch('/attendances/bulk_update_modal', options)
      .then((response) => {
        if (!response.ok) {
          throw new Error(response.statusText)
        } else {
          return response.text()
        }
      })
      .then((html) => {
        const wrapper = document.querySelector('#custom-order-dialog')
        wrapper.innerHTML = html

        const controllerEl = wrapper.querySelector('[data-controller~="edit-attendances-modal"]')
        if (controllerEl) {
          controllerEl.dataset.editAttendancesModalMarkAll = !!this.shouldMarkAll
        } else {
          logger.info('Bulk Update Controller Not Found')
        }

        $(wrapper).dialog({
          title: 'Edit Attendance',
          modal: true,
          resizable: true,
          width: "40%",
        })
      })
      .catch((error) => {
        document.getElementById("flash-message").innerHTML = error;
      })
      .finally(() => {
        document.getElementById("ajax_working").style.display = "none";
      })
  }

  reload() {
    this.debouncedLoad(null, true)
  }

  setActive(ev) {
    const el = ev.currentTarget
    const actives = el.closest('.dropdown-menu')?.querySelectorAll?.('.active')
    if (!actives) return

    actives.forEach((item) => {
      item.classList.remove('active')
    })

    el.classList.add('active')
  }

  setMarkAllText() {
    if (this.hasMarkAllButtonTarget) {
      this.markAllButtonTarget.innerText = this.shouldMarkAll
        ? 'Mark All'
        : 'Mark Selected'
    }
  }
}
